import { Routes, Route } from 'react-router-dom';

import Root from './views/Root/Root';
import NotFound from './views/NotFound/NotFound';

export const App = () => {
	return (
		<div className='app'>
			<Routes>
				<Route path='/'>
					<Route index element={<Root />} />
					<Route path='*' element={<NotFound />} />
				</Route>
			</Routes>
		</div>
	);
};
