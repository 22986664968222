import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyles } from './components/GlobalStyles';
import { App } from './App';
import { tracking } from './utils/tracking';

window.addEventListener('click', (event) => tracking(event));

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<BrowserRouter>
			<GlobalStyles />
			<App />
		</BrowserRouter>
	</React.StrictMode>
);
