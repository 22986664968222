import mgLogo from '../../assets/logos/mg_logo_white.svg';

import './styles.css';
import { Logo } from 'components/Logo';

const Root = () => {
	return (
		<div
			className='root'
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
			}}
		>
			<Logo src={mgLogo} width='210px' alt='Muse Group Fundraising' />
		</div>
	);
};

export default Root;
