export const tracking = (event) => {
	if (!event.target.getAttribute('data-tracking-action')) return;

	const el = event.target;
	const trackingEventAction = el.getAttribute('data-tracking-action');
	const trackingEventCategory = el.getAttribute('data-tracking-category');
	const trackingEventLabel = el.getAttribute('data-tracking-label');

	window.gtag('event', trackingEventAction, {
		event_category: trackingEventCategory,
		event_label: trackingEventLabel,
	});
};
