import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  // CSS RESET
    /***
        The new CSS reset - version 1.5.1 (last updated 1.3.2022)
        GitHub page: https://github.com/elad2412/the-new-css-reset
    ***/

    /*
        Remove all the styles of the "User-Agent-Stylesheet", except for the 'display' property
        - The "symbol *" part is to solve Firefox SVG sprite bug
    */
        *:where(:not(iframe, canvas, img, svg, video):not(svg *, symbol *)) {
      all: unset;
      display: revert;
    }

    /* Preferred box-sizing value */
    *,
    *::before,
    *::after {
      box-sizing: border-box;
    }

    /* Reapply the pointer cursor for anchor tags */
    a,
    button {
      cursor: revert;
    }

    /* Remove list styles (bullets/numbers) */
    ol,
    ul,
    menu {
      list-style: none;
    }

    /* For images to not be able to exceed their container */
    img {
      max-width: 100%;
    }

    /* removes spacing between cells in tables */
    table {
      border-collapse: collapse;
    }

    /* revert the 'white-space' property for textarea elements on Safari */
    textarea {
      white-space: revert;
    }

    /* minimum style to allow to style meter element */
    meter {
      -webkit-appearance: revert;
      appearance: revert;
    }

    /* reset default text opacity of input placeholder */
    ::placeholder {
      color: unset;
    }

    /* fix the feature of 'hidden' attribute.
    display:revert; revert to element instead of attribute */
    :where([hidden]) {
      display: none;
    }

    /* revert for bug in Chromium browsers
    - fix for the content editable attribute will work properly. */
    :where([contenteditable]) {
      -moz-user-modify: read-write;
      -webkit-user-modify: read-write;
      overflow-wrap: break-word;
      -webkit-line-break: after-white-space;
    }

    /* apply back the draggable feature - exist only in Chromium and Safari */
    :where([draggable='true']) {
      -webkit-user-drag: element;
    }

  :root {
    --color-gray-200: #6E777A;
    --color-gray-300: #5C6467;
    --color-gray-350: #3f4a4f;
    --color-gray-400: #283135;
    --color-gray-500: #1C2427;

    --color-white: #FFFFFF;
    --color-green: #85F4AD;
    --radius-1: 10px;
    --radius-4: 4px;

    --container-bigger: 1440px;
    --container-normal: 1322px;

    --breakpoint-desktop-large: 1600px;
    --breakpoint-desktop: 1280px;
    --breakpoint-tablet: 900px;
    --breakpoint-mobile: 600px;

    --ff-title: "Taviraj";
    --ff-body: "Inter";

    --fw-700: 700;
    --fw-600: 600;
    --fw-500: 500;
    --fw-400: 400;
    --fw-300: 300;

    --title-fz: 30px;
    --title-lh: 42px;

    --cta-fz: 16px;
    --cta-lh: 18px;

    --fz-body-big: 20px;
    --fz-body-normal: 18px;
    --fz-body-regular: 16px;
    --fz-body-small: 14px;
    --fz-body-smaller: 12px;

    --p-bigger: 160px;
    --p-big: 64px;
    --p-normal-big: 40px;
    --p-normal: 34px;
    --p-normaller: 24px;
    --p-small: 20px;
    --p-smaller: 14px;
  }

  @media (max-width: 1000px) {
    :root {
      --title-fz: 24px;
      --title-lh: 30px;
    }
  }

  @media (max-width: 900px) {
    :root {
      --title-fz: 24px;
      --title-lh: 30px;

      --fz-body-big: 18px;
      --fz-body-normal: 16px;
      --fz-body-regular: 14px;
      --fz-body-small: 12px;
    }
  }

  html,body{
    font-family: var(--ff-body);
    font-weight: var(--fw-300);
    color: var(--color-white);
    line-height: 1.42859;
    overflow-x: hidden;

    @media (max-width:900px){
      font-weight: var(--fw-400);
    }
  }

  .bold {
    font-weight: var(--fw-600);
  }

  .h2 {
    font-family: var(--ff-title);
    font-weight: var(--fw-300);
    font-size: var(--title-fz);
    line-height: var(--title-lh);
    padding-bottom: var(--p-normaller);
    @media (max-width: 900px) {
      font-weight: var(--fw-400);
    }
  }

  .body {
    font-size: var(--fz-body-normal);
    line-height: 21px;

    &-bold {
      font-weight: var(--fw-500);
    }
  }

  .subBody {
    font-size: var(--fz-body-small);
    font-weight: var(--fw-600);
    line-height: 20px;
  }

  .cta {
    font-family: var(--ff-title);
    font-size: var(--cta-fz);
    line-height: var(--cta-lh);
    font-weight: var(--fw-600);
  }

  .container {
    width: 100%;
    max-width: calc(var(--container-bigger));
    margin: 0 auto;
    padding-right: var(--p-normal-big);
    padding-left: var(--p-normal-big);

    &__inner {
      width: 100%;
      max-width: calc(var(--container-bigger));
      margin: 0 auto;
      padding-right: var(--p-normal-big);
      padding-left: var(--p-normal-big);
      @media (max-width: 900px){
        padding-right: var(--p-small);
        padding-left: var(--p-small);
      }
    }
  }

  .section {
    padding-bottom: var(--p-bigger);

    &__header {
      padding-bottom: var(--p-big);
      @media (max-width: 900px){
        padding-bottom: 44px;
      }
    }

    @media (max-width: 900px){
      padding-bottom: var(--p-big);
    }
  }


  // utilities
  // // // // // /
  .underline {
    position: relative;
    text-decoration: none;
    padding-bottom: 3px;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      transform: scaleX(0);
      transition: transform 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
    }

    &:hover::after,
    &:focus::after {
      transform: scaleX(1);
    }

    &--primary {
      &::after {
        background-color: var(--color-white);
      }
    }

    &--secondary {
      &::after {
        background-color: var(--color-gray-500);
      }
    }

    &--link {
      display: inline-block;
      color: var(--color-green);
      line-height: 20px;

      &::after {
        background-color: var(--color-green);
      }
    }

  }

  .mobileLine {
    width: 100%;
    height: 1px;
    background: var(--color-gray-350);
    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  .swiper {
    padding-bottom: 40px;
    overflow: inherit;
  }

  .swiper-pagination-bullet {
    background: var(--color-green);
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    &:after {
      font-size: 30px;
    }
  }

`;
